import env from '@/env'
export default function(to,target){
    var form=document.createElement("form");
    var tokenInput=document.createElement("input");
    var urlInput=document.createElement("input");

    tokenInput.name="token";
    tokenInput.value=localStorage.xbToken;
    tokenInput.type="hidden";

    urlInput.name="to";
    urlInput.value=to;
    urlInput.type="hidden";

    form.appendChild(tokenInput);
    form.appendChild(urlInput);
    form.method="post";
    form.target=target||"";
    form.action=env.XBX_MAIN_API+"/v2/auth/redirect";

    document.body.appendChild(form);

    form.submit();
}