import axios from 'axios'
import { Message,Notification  } from 'element-ui'
import store from '@/store/index'
import env from '@/env'
// create an axios instance
const service = axios.create({
  baseURL: env.XBX_BASE_API, // api的base_url
  timeout: 20000, // request timeout
  //withCredentials: true
})

// request interceptor
service.interceptors.request.use(config => {
  // Do something before request is sent
  config.headers.Authorization="Bearer "+localStorage.xbToken
  config.headers["X-Requested-With"]="XMLHttpRequest"
  return config
}, error => {
  console.log(error) // for debug
  Promise.reject(error)
})

// respone interceptor

service.interceptors.response.use(
  response => response,
  error => {
    console.log('err' + error) // for debug
    window.app_last_err=error;
    if(error.response.status!=401||error.response.data.msg)Notification({
        title:"出错了！",
      message: app_last_err.response.data.msg||error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  })

export default service
