<template>
  <div class="navbar" mode="horizontal">
    <hamburger class="hamburger-container" :toggleClick="toggleSideBar" :isActive="sidebar.opened"></hamburger>

    <breadcrumb class="breadcrumb-container"></breadcrumb>

    <div class="right-menu">
      <error-log class="errLog-container right-menu-item"></error-log>
      <el-popover popper-class="userPanel" @show="userPanel=true" @hide="userPanel=false"  placement="bottom" transition="el-zoom-in-top" width="200" trigger="hover">
        <div class="userBox">
            <div class="userName">{{$store.state.user.name}}</div>

            <div class="userMeta">
              <div class="userWX">
                <div class="m1"><i class="fa fa-address-card" aria-hidden="true"></i> 校园网：
                </div><el-tag size="mini" :type="$store.state.user.data.yz_account?'success':'info'">{{$store.state.user.data.yz_realname||$store.state.user.data.yz_account||"未绑定"}}</el-tag>
              </div>
            </div>
              <div class="userTag">
                <el-tag size="small">{{$store.state.user.data.type_cn}}</el-tag>
                <!--el-tag size="small">{{$store.state.user.data.level}}</el-tag>
                <el-tag size="small">
                  <i class="fa fa-money" aria-hidden="true"></i>
                  {{$store.state.user.data.points}}
                </el-tag-->
              </div>
            <div class="userEditLink">
              <el-button id="avatar_pick_btn" size="mini" type="text"><i class="fa fa-camera-retro" aria-hidden="true"></i> 换头像</el-button>
              <el-button @click="editPanel=true" size="mini" type="text"><i class="fa fa-pencil-square" aria-hidden="true"></i>  改资料</el-button>
              <el-button @click="logout"  size="mini" type="text"><i class="fa fa-sign-out" aria-hidden="true"></i>  退出</el-button>
            </div>
        </div>

        <div slot="reference" :class="{open:userPanel}" class="avatar-container right-menu-item">
            <div class="avatar-wrapper">
              <img class="user-avatar" :src="avatar">
            </div>
        </div>
      </el-popover>
      <el-tooltip effect="dark" content="变色" placement="bottom">
        <theme-picker class="theme-switch right-menu-item"></theme-picker>
      </el-tooltip>
      <el-button @click="kf" target="_blank" size="mini" class="kfBtn right-menu-item">
        客服
      </el-button>
    </div>
    <avatar-cropper
    @uploading="beforeUpload"
      @uploaded="handleUploaded"
      trigger="#avatar_pick_btn"
      :upload-url="upload_api" />
<el-dialog :append-to-body="true" title="个人资料" :visible.sync="editPanel">
  <el-form>
    <el-form-item label="昵称">
      <el-input v-model="form.name" auto-complete="off"></el-input>
    </el-form-item>
    <el-form-item label="邮箱">
      <el-input v-model="form.email" auto-complete="off"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="editPanel = false">取 消</el-button>
    <el-button :loading="editLoading" type="primary" @click="editUser">确 定</el-button>
  </div>
</el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import ErrorLog from '@/components/ErrorLog'
import ThemePicker from '@/components/ThemePicker'
import { Message } from 'element-ui'
import request from '@/utils/request'


export default {
  data:function(){
    return {
      userPanel:false,
      editPanel:false,
      editLoading:false,
      upload_api:this.$xbxenv.XBX_BASE_API+"/files/upload_avatar",
      form:{
        name:this.$store.state.user.data.display_name,
        email:this.$store.state.user.data.user_email
      }
    }
  },
  components: {
    Breadcrumb,
    Hamburger,
    ErrorLog,
    AvatarCropper:() => import('vue-avatar-cropper'),
    ThemePicker
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'name',
      'avatar'
    ])
  },
  methods: {
    kf(){
      location.href=`http://wpa.qq.com/msgrd?v=3&uin=2365699148`
    },
    beforeUpload:function(a,xhr){
        xhr.withCredentials=true;
    },
    editUser:function(){
        var that=this;
        that.editLoading=true;
        request({
            url: '/user/edit',
            method:"post",
            data:this.form
        }).then(function(e){
            that.$store.dispatch('GetUserInfo').then(() => {
                that.editLoading=false;
                that.editPanel=false;
                Message({
                    message: "资料更改成功",
                    type: 'success',
                })
            }).catch(function(){
                that.editLoading=false;
            })
        });
    },
    handleUploaded:function(response){
        Message({
            message: "头像更改成功",
            type: 'success',
        })
        this.$store.commit('SET_AVATAR', response.url)
    },
    toggleSideBar() {
      this.$store.dispatch('toggleSideBar')
    },
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        location.reload()// In order to re-instantiate the vue-router object to avoid bugs
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.kfBtn{
  padding-left:10px;
  padding-right:10px;
}
.hideSidebar .navbar {
    left: 36px;
}
.mobile .navbar {
    left: 0px;
}
.mobile.hideSidebar .navbar {
    left: 0px;
}
.navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 180px;
    height: 50px;
    border-bottom: 1px solid #fff0f0;
    z-index: 99;
    background: #fff;
    transition: left .28s;
  line-height: 50px;
  border-radius: 0px !important;
  .hamburger-container {
    line-height: 58px;
    height: 50px;
    float: left;
    padding: 0 10px;
  }
  .breadcrumb-container{
    float: left;
  }
  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }
  .right-menu {
    float: right;
    height: 100%;
    &:focus{
     outline: none;
    }
    .right-menu-item {
      display: inline-block;
      margin-right: 8px;
      vertical-align: middle;
    }
    .screenfull {
      height: 20px;
    }
    .international{
      vertical-align: top;
    }
    .avatar-container {
      height: 50px;
      margin-right: 10px;
      .avatar-wrapper {
        cursor: pointer;
        margin-top: 5px;
        position: relative;
        transition:all 0.2s;
        transform-origin:top center;
        .user-avatar {
          width: 40px;
          height: 40px;
          border-radius: 10px;
        transition:all 0.2s;
        }
        .userInfo {
            float: right;
            display: block;
            height: 40px;
            line-height: 42px;
            margin-left: 10px;
            vertical-align: middle;
            color: #304156;
        }
      }
    }
  }
}
.userBox{
  text-align:center;
  .userName{
    font-size:20px;
  }
  .userMeta{
    text-align:left;
    .m1{
      width:110px;
      text-align:center;
      display:inline-block;
    }
  }
}
.open .avatar-wrapper {
    z-index: 9999;
    margin-top: 0px;
    transform:scale(1.8);
}

.open .user-avatar {
    border-radius: 50% !important;
}
</style>
<style>

.userPanel.el-popper {
    margin-top: -1px !important;
    padding-top: 30px;
    border-top: 0;
    z-index: 9 !important;
}
.userEditLink {
    margin-top: 10px;
    margin-bottom:-12px;
    background: #f4f5f7;
    margin-left: -12px;
    margin-right: -12px;
}
</style>