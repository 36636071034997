<template>
  <div class="theme-picker theme-switch right-menu-item el-tooltip el-color-picker el-color-picker--mini">
    <el-popover placement="bottom" width="175" trigger="click">
      <div class="el-color-picker__trigger" slot="reference">
        <span class="el-color-picker__color" style="border:0;">
          <span class="el-color-picker__color-inner" :style="{backgroundColor:theme}"></span>
        </span>
        <span class="el-color-picker__icon el-icon-arrow-down"></span>
      </div>
      <div class="colorbox">
        <div class="colorLine" v-for="(i,a) in dfColors" :key="'line-'+a">
          <div class="colorTitle">
            {{a}}
          </div>
          <div class="colorBlock" v-for="(ii,aa) in i" :key="'block-'+aa">
            <div class="colorPvw" @click="theme=ii.toUpperCase()" :style="{background:ii}"></div>
            <div class="colorActive" v-if="theme==ii.toUpperCase()">
              <i class="el-icon-check"></i>
            </div>
          </div>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
const version = require('element-ui/package.json').version // element-ui version from node_modules
const ORIGINAL_THEME = '#409EFF' // default color
import env from '@/env';
import achieve from '@/utils/achievements'
import rClient from 'webpack-theme-color-replacer/client'
import rForElementUI from 'webpack-theme-color-replacer/forElementUI'
import 'element-ui/lib/theme-chalk/color-picker.css';
const favicon_orig = require("@/assets/image/favicon.png");
const xbxicon_canvas=document.createElement("canvas");
xbxicon_canvas.width = 128;
xbxicon_canvas.height = 128;
const xbxicon_image=new Image();
xbxicon_image.src=favicon_orig;
function set_favico(hex){
  var canvas = xbxicon_canvas;
  var color=[parseInt("0x" + hex.slice(1, 3)),parseInt("0x" + hex.slice(3, 5)),parseInt("0x" + hex.slice(5, 7))];
  var ctx = canvas.getContext("2d");
  ctx.drawImage(xbxicon_image,0,0);
  var pixels = 	ctx.getImageData(0,0,canvas.width,canvas.height);
  for (var i = 0; i < pixels.data.length; i += 4) {
    pixels.data[i]   = color[0];
    pixels.data[i+1] = color[1];
    pixels.data[i+2] = color[2];
  }
  ctx.putImageData(pixels,0,0);
  if(env.XBX_APP_ENV=="test"||env.XBX_APP_ENV=="development"){
    ctx.fillStyle="rgba(0,0,0,0.5)";
    ctx.fillRect(70,50,58,78);
    ctx.fillStyle="#fff";
    ctx.font="bold 90px Consolas";
    ctx.fillText(env.XBX_APP_ENV=="test"?"T":"D",75,120);
  }
  var du=(canvas.toDataURL());
  ctx.clearRect(0,0,canvas.width,canvas.height);  
  document.querySelector("link[rel='shortcut icon']").href = du;
}
export default {
  data() {
    return {
      dfColors:{
        "默认":['#FE6565',"#409EFF","#67C23A","#303133"],
        "μ's":["#efa200","#45b0e3","#b1b2b2","#a5579e","#e35da6","#79b123","#f1c800","#cb4a4a","#0069b2"],
        "VOCALOID":["#66ccff","#39C5BB","#ee0000","#9999ff","#FFA500"],
      },
      chalk: '', // content of theme-chalk css
      theme: '#FE6565',
      used:[]
    }
  },
  watch: {
    theme(val, oldVal) {
      localStorage.xbx_theme=val;
      this.$root.theme=val;
        set_favico(val);
      if(val=="#efa200")this.used=[];
      this.used.push(val);
      if(val=="#FE6565")this.used=[];
      if(this.used.join(",").toLowerCase()==this.dfColors["μ's"].join(",").toLowerCase())achieve(5);
      console.log(this.used.join(",").toLowerCase(),this.dfColors["μ's"].join(",").toLowerCase(),this.used.join(",").toLowerCase()==this.dfColors["μ's"].join(",").toLowerCase());
      if (typeof val !== 'string') return;

      var options = {
        newColors: [...rForElementUI.getElementUISeries(val)],
      }
      rClient.changer.changeColor(options, Promise);
      /*
      const themeCluster = this.getThemeCluster(val.replace('#', ''))
      const originalCluster = this.getThemeCluster(oldVal.replace('#', ''))
      const getHandler = (variable, id) => {
        return () => {
          const originalCluster = this.getThemeCluster(ORIGINAL_THEME.replace('#', ''))
          const newStyle = this.updateStyle(this[variable], originalCluster, themeCluster)

          let styleTag = document.getElementById(id)
          if (!styleTag) {
            styleTag = document.createElement('style')
            styleTag.setAttribute('id', id)
            document.head.appendChild(styleTag)
          }

          styleTag.innerText = newStyle
        }
      }

      const chalkHandler = getHandler('chalk', 'chalk-style')

      if (!this.chalk) {
        const url = `https://lib.baomitu.com/element-ui/${version}/theme-chalk/index.css`
        this.getCSSString(url, chalkHandler, 'chalk')
      } else {
        chalkHandler()
      }

      const styles = [].slice.call(document.querySelectorAll('style'))
        .filter(style => {
          const text = style.innerText
          return new RegExp(oldVal, 'i').test(text) && !/Chalk Variables/.test(text)
        })
      styles.forEach(style => {
        const { innerText } = style
        if (typeof innerText !== 'string') return
        style.innerText = this.updateStyle(innerText, originalCluster, themeCluster)
      })
      /*this.$message({
        message: '变色成功',
        type: 'success'
      })*/
    }
  },
  created:function(){
    if(localStorage.xbx_theme){
        this.theme=localStorage.xbx_theme;
    }
    this.$root.theme=this.theme;
    set_favico(this.theme)
  },
  methods: {
    updateStyle(style, oldCluster, newCluster) {
      let newStyle = style
      oldCluster.forEach((color, index) => {
        newStyle = newStyle.replace(new RegExp(color, 'ig'), newCluster[index])
      })
      return newStyle
    },

    getCSSString(url, callback, variable) {
      const xhr = new XMLHttpRequest()
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          this[variable] = xhr.responseText.replace(/@font-face{[^}]+}/, '')
          this[variable]=this[variable]+"\r\n.xbx-theme-color{color:#409EFF}.xbx-theme-background{background-color:#409EFF}.xbx-theme-border{border-color:#409EFF}";
          callback()
        }
      }
      xhr.open('GET', url)
      xhr.send()
    },

    getThemeCluster(theme) {
      const tintColor = (color, tint) => {
        let red = parseInt(color.slice(0, 2), 16)
        let green = parseInt(color.slice(2, 4), 16)
        let blue = parseInt(color.slice(4, 6), 16)

        if (tint === 0) { // when primary color is in its rgb space
          return [red, green, blue].join(',')
        } else {
          red += Math.round(tint * (255 - red))
          green += Math.round(tint * (255 - green))
          blue += Math.round(tint * (255 - blue))

          red = red.toString(16)
          green = green.toString(16)
          blue = blue.toString(16)

          return `#${red}${green}${blue}`
        }
      }

      const shadeColor = (color, shade) => {
        let red = parseInt(color.slice(0, 2), 16)
        let green = parseInt(color.slice(2, 4), 16)
        let blue = parseInt(color.slice(4, 6), 16)

        red = Math.round((1 - shade) * red)
        green = Math.round((1 - shade) * green)
        blue = Math.round((1 - shade) * blue)

        red = red.toString(16)
        green = green.toString(16)
        blue = blue.toString(16)

        return `#${red}${green}${blue}`
      }

      const clusters = [theme]
      for (let i = 0; i <= 9; i++) {
        clusters.push(tintColor(theme, Number((i / 10).toFixed(2))))
      }
      clusters.push(shadeColor(theme, 0.1))
      return clusters
    }
  }
}
</script>

<style>
.theme-picker .el-color-picker__trigger {
  vertical-align: middle;
}

.theme-picker-dropdown .el-color-dropdown__link-btn {
  display: none;
}
.colorTitle{
  font-size:12px;
  color:#999;
}
.colorBlock {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 2px;
    cursor:pointer;
    position: relative;
}

.colorPvw {
    display: inline-block;
    width: 100%;
    height: 100%;
    cursor:pointer;
    border:1px solid transparent;
}
.colorPvw:hover{
  border-color:#000;
}
.colorActive {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom:0;
    text-align:center;
    padding-top:2px;
    right:0;
    left:0;
    color:#fff;
    font-size:17px;
}
</style>
