import Vue from 'vue'
import env from '@/env'
function formatComponentName(vm){
    if (vm.$root === vm) return 'root';
    var name = vm._isVue ? (vm.$options && vm.$options.name) || (vm.$options && vm.$options._componentTag) : vm.name;
    return (name ? 'component <' + name + '>' : 'anonymous component') + (vm._isVue && vm.$options && vm.$options.__file ? ' at ' + (vm.$options && vm.$options.__file) : '');
}
/*
// you can set only in production env show the error-log
// if (process.env.NODE_ENV === 'production') {

Vue.config.errorHandler = function(err, vm, info, a) {
  // Don't ask me why I use Vue.nextTick, it just a hack.
  // detail see https://forum.vuejs.org/t/dispatch-in-vue-config-errorhandler-has-some-problem/23500
  Vue.nextTick(() => {
    store.dispatch('addErrorLog', {
      err,
      vm,
      info,
      url: window.location.href
    })
    var componentName = formatComponentName(vm);  var propsData = vm.$options && vm.$options.propsData; 
    /*fundebug.notifyError(err,  {
      metaData:      {          componentName: componentName,          propsData: propsData,          info: info      }   });
    
    console.error(err, info)
  })
}
*/
import * as Sentry from '@sentry/browser';
import {Vue as IntegrationsVue} from '@sentry/integrations';
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY,
        release:env.XBX_APP_GIT.SHA,
        integrations: [new IntegrationsVue({Vue, attachProps: true})],
    });
}
// }
