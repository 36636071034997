import {Pagination} from 'element-ui';
import {Dialog} from 'element-ui';
import {Dropdown} from 'element-ui';
import {DropdownMenu} from 'element-ui';
import {DropdownItem} from 'element-ui';
import {Input} from 'element-ui';
import {InputNumber} from 'element-ui';
import {Radio} from 'element-ui';
import {RadioGroup} from 'element-ui';
import {RadioButton} from 'element-ui';
import {Checkbox} from 'element-ui';
import {CheckboxButton} from 'element-ui';
import {CheckboxGroup} from 'element-ui';
import {Switch} from 'element-ui';
import {Select} from 'element-ui';
import {Option} from 'element-ui';
import {OptionGroup} from 'element-ui';
import {Button} from 'element-ui';
import {ButtonGroup} from 'element-ui';
import {Table} from 'element-ui';
import {TableColumn} from 'element-ui';
import {Popover} from 'element-ui';
import {Tooltip} from 'element-ui';
import {MessageBox} from 'element-ui';
import {Breadcrumb} from 'element-ui';
import {BreadcrumbItem} from 'element-ui';
import {Form} from 'element-ui';
import {FormItem} from 'element-ui';
import {Tag} from 'element-ui';
import {Alert} from 'element-ui';
import {Notification} from 'element-ui';
import {Loading} from 'element-ui';
import {Icon} from 'element-ui';
import {Row} from 'element-ui';
import {Col} from 'element-ui';
import {Message} from 'element-ui';
import {Badge} from 'element-ui';
import {Card} from 'element-ui';
import {Scrollbar} from 'element-ui';
import {Container} from 'element-ui';
import {Header} from 'element-ui';
import {Aside} from 'element-ui';
import {Main} from 'element-ui';
import {Footer} from 'element-ui';

const components = [
  Pagination,
  Dialog,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  Popover,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tag,
  Alert,
  Icon,
  Row,
  Col,
  Badge,
  Card,
  Scrollbar,
  Container,
  Header,
  Aside,
  Main,
  Footer,
];

const install = function(Vue, opts = {}) {

  components.forEach(component => {
    Vue.component(component.name, component);
  });

  Vue.use(Loading.directive);

  Vue.prototype.$ELEMENT = {
    size: opts.size || '',
    zIndex: opts.zIndex || 2000
  };

  Vue.prototype.$loading = Loading.service;
  Vue.prototype.$msgbox = MessageBox;
  Vue.prototype.$alert = MessageBox.alert;
  Vue.prototype.$confirm = MessageBox.confirm;
  Vue.prototype.$prompt = MessageBox.prompt;
  Vue.prototype.$notify = Notification;
  Vue.prototype.$message = Message;

};
export default {install};