<template>
  <main id="tokiapp">
    <router-view></router-view>
  </main>
</template>

<script>
  export default{
    name: 'App'
  }
</script>
