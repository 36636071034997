import { Notification  } from 'element-ui'
import request from '@/utils/request'
import ucan from '@/utils/permission'
export default function achieve(id){
    if(!ucan(["xbx_achievements"])&&id!=1)return false;
    request({
        url: '/achievements/'+id,
    }).then(function(e){
        if(e.data.msg){
            if(id==1){
                return location.href=process.env.BASE_URL+"ac/?first=1"
            }
            Notification ({
                duration:0,
                dangerouslyUseHTMLString: true,
                message: '<div class="medal"></div><strong>成就：『'+e.data.title+'』</strong><div>'+e.data.msg+'</div>'
            });
        }
    });
}