<template>
  <div class="menu-wrapper">
    <template v-for="item in routes" v-if="!item.hidden&&item.children">
      <template v-if="hasOneShowingChildren(item.children) && !item.children[0].children&&!item.alwaysShow">
        <router-link exact active-class="xmenu-active xbx-theme-background xbx-theme-background-hover" class="xmenu-link" v-if="!item.children[0].meta||!item.children[0].meta.link" :key="item.children[0].name" :to="item.path+'/'+item.children[0].path">
          <div :index="item.path+'/'+item.children[0].path" :class="{'submenu-title-noDropdown':!isNest}">
            <svg-icon v-if="item.children[0].meta&&item.children[0].meta.icon" :icon-class="item.children[0].meta.icon"></svg-icon>
            <i v-if="item.children[0].meta&&item.children[0].meta.fa_icon" :class="item.children[0].meta.fa_icon" class="fa svg-icon" aria-hidden="true"></i>
            <span v-if="item.children[0].meta&&item.children[0].meta.title" slot="title">{{(item.children[0].meta.title)}}</span>
          </div>
        </router-link>
         <a class="xmenu-link" v-else @click="clickLink(child.meta,$event)" :href="item.children[0].meta.link" :key="item.children[0].name">
          <div :index="item.path+'/'+item.children[0].path" :class="{'submenu-title-noDropdown':!isNest}">
            <svg-icon v-if="item.children[0].meta&&item.children[0].meta.icon" :icon-class="item.children[0].meta.icon"></svg-icon>
            <i v-if="item.children[0].meta&&item.children[0].meta.fa_icon" :class="item.children[0].meta.fa_icon" class="fa svg-icon" aria-hidden="true"></i>
            <span v-if="item.children[0].meta&&item.children[0].meta.title" slot="title">{{(item.children[0].meta.title)}}</span>
          </div>
        </a>
      </template>

      <div class="xmenu-child" v-else :index="item.name||item.path" :key="item.name">
        <router-link active-class="xmenu-active xbx-theme-background xbx-theme-background-hover" class="xmenu-link" :to="item.linkPath||item.path">
          <div class="xmenu-child-title">
            <svg-icon v-if="item.meta&&item.meta.icon" :icon-class="item.meta.icon"></svg-icon>
            <i v-if="item.meta&&item.meta.fa_icon" :class="item.meta.fa_icon" class="fa svg-icon" aria-hidden="true"></i>
            <span v-if="item.meta&&item.meta.title" slot="title">{{(item.meta.title)}}</span>
          </div>
        </router-link>
        <div class="xmenu-child-menu">
          <div v-for="child in item.children" v-if="!child.hidden" :key="child.path">
            <sidebar-item :is-nest="true" class="nest-menu" v-if="child.children&&child.children.length>0" :routes="[child]"></sidebar-item>
            <template  v-else>
              <router-link exact active-class="xmenu-child-active xbx-theme-color xbx-theme-color-i" class="xmenu-link xmenu-link-child" v-if="!child.meta||!child.meta.link" :to="item.path+'/'+child.path">
                <div>
                  <svg-icon v-if="child.meta&&child.meta.icon" :icon-class="child.meta.icon"></svg-icon>
                  <i v-if="child.meta&&child.meta.fa_icon" :class="child.meta.fa_icon" class="fa svg-icon" aria-hidden="true"></i>
                  <span v-if="child.meta&&child.meta.title" slot="title">{{(child.meta.title)}}</span>
                </div>
              </router-link>
              <a v-else class="xmenu-link xmenu-link-child" :href="child.meta.link" @click="clickLink(child.meta,$event)">
                  <svg-icon v-if="child.meta&&child.meta.icon" :icon-class="child.meta.icon"></svg-icon>
                  <i v-if="child.meta&&child.meta.fa_icon" :class="child.meta.fa_icon" class="fa svg-icon" aria-hidden="true"></i>
                  <span v-if="child.meta&&child.meta.title" slot="title">{{(child.meta.title)}}</span>
              </a>
            </template>
          </div>
        </div>
      </div>

    </template>
  </div>
</template>

<script>
import {  } from '@/utils/i18n'
import authRedirect from '@/utils/authredirect'
export default {
  name: 'SidebarItem',
  props: {
    routes: {
      type: Array
    },
    isNest: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleMenu:function(id){
      console.log(id);
    },
    clickLink(meta,ev){
      if(meta.authredirect){
        ev.preventDefault();
        authRedirect(meta.link,"_blank");
      }
    },
    hasOneShowingChildren(children) {
      const showingChildren = children.filter(item => {
        return !item.hidden
      })
      if (showingChildren.length === 1) {
        return true
      }
      return false
    },
    
  }
}
</script>

