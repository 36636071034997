import Vue from 'vue'
import Router from 'vue-router'
import env from './env'

Vue.use(Router)

/* Layout */
import Layout from '@/views/layout/Layout'
import emptyLayout from '@/views/layout/emptyLayout'
import cmsLayout from '@/pages/cms/layout'

/** note: submenu only apppear when children.length>=1
*   detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
**/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin','editor']     will control the page roles (you can set multiple roles)
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
                   if true ,the page will no be cached(default is false)
  }
**/
export const constantRouterMap = [
  { path: '/login', component: () => import(/* webpackChunkName: "auth.2021login" */'@/views/login/2021Login'), hidden: true },
  { path: '/503', component: () => import(/* webpackChunkName: "common.503" */'@/views/errorPage/503'), hidden: true },
  { path: '/', redirect:"/user", hidden: true },
  //{ path: '/authredirect', component: () => import('@/views/login/authredirect'), hidden: true },
  { path: '/404', component: () => import(/* webpackChunkName: "common.404" */'@/views/errorPage/404'), hidden: true },
  //{ path: '/401', component: () => import('@/views/errorPage/401'), hidden: true },
  {
    path: '/user',
    component: Layout,
    children: [{
      path: '',
      component: () => import(/* webpackChunkName: "common.index" */'@/views/dashboard/index'),
      name: 'dashboard',
      meta: { title: '个人中心', fa_icon: 'fa-user-circle', }
    }],
    meta: { title: '首页' }
  },
  {
    path: '/cms',
    component: Layout,
    linkPath:'/cms/post',
    redirect:'/cms/post',
    children: [{
      component: cmsLayout,
      path: '',
      hidden:true,
      children:[
        {
          path: 'post',
          name: 'cms.new',
          meta: { title: '所有文章',listBy:"" },
          component: () => import(/* webpackChunkName: "cms.common" */'@/pages/cms/list'),
        },
        {
          path: 'author/*',
          name: 'cms.author',
          hidden:true,
          meta: { title: '作者',listBy:"author" },
          component: () => import(/* webpackChunkName: "cms.common" */'@/pages/cms/list'),
        },
        {
          path: 'category/*',
          name: 'cms.cat',
          hidden:true,
          meta: { title: '分类',listBy:"category" },
          component: () => import(/* webpackChunkName: "cms.common" */'@/pages/cms/list'),
        },
        {
          path: 'post/:id(\\d+)',
          name: 'cms.single',
          hidden:true,
          meta: { title: '阅读文章' },
          component: () => import(/* webpackChunkName: "cms.common" */'@/pages/cms/single')
        }
      ]
    }],
    meta: { title: '文章', fa_icon: 'fa-book'},
  },
  /*
  {
    path: '/go/xyw',
    component: Layout,
    redirect: '',
    children: [{
      path: '',
      component: () => import(/* webpackChunkName: "auth.xyw" *//*'@/pages/go/xyw'),
      name: 'goxyw',
      meta: { title: '校园网', fa_icon: 'fa-paper-plane', }
    }]
  },*/
]
var router=new Router({
  mode: 'history', // require service support
  base:env.XBX_BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
router.afterEach(function(route){
    try{ _hmt.push(['_trackPageview',"/i"+route.path]);}catch(e){}
});
export default router;
export const asyncRouterMap = [
{
    path: '/ac',
    component: Layout,
    redirect: '',
    meta: { roles: ['xbx_achievements']  },
    children: [
      { path: '', component: () => import(/* webpackChunkName: "common.achievements" */'@/pages/achievements/list'), name: '成就馆', meta: { title: '成就馆',fa_icon:"fa-trophy", roles: ['xbx_achievements']  }},
    ]
  },
  {
    path: '/score',
    component: Layout,
    redirect: '/score',
    meta: {
      title: '你的名次',
      fa_icon: 'fa-star'
    },
    children: [
      { path: '', component: () => import(/* webpackChunkName: "hfs.query" */'@/pages/score/query'), name: 'score_query', meta: { exact:true,title: '成绩查询', }},
      { path: 'average', component: () => import(/* webpackChunkName: "hfs.avg" */'@/pages/score/average'), name: 'score_average', meta: { title: '均分PK', }},
      { path: 'julao', component: () => import(/* webpackChunkName: "hfs.top" */'@/pages/score/julao'), name: 'score_julao', meta: { title: 'julao在哪里', }},
      { path: 'manage', component: () => import(/* webpackChunkName: "hfs.manage" */'@/pages/score/manage'), name: 'score_mgr', meta: { title: '成绩管理', roles: ['publish_posts', 'app_yourscore'] }},
      { path: 'upload', component: () => import(/* webpackChunkName: "hfs.upload" */'@/pages/score/upload'), name: 'score_upload', meta: { title: '数据上传',roles: ['publish_posts', 'app_yourscore']}}
    ]
  },
  {
    path: '/activity',
    component: Layout,
    alwaysShow:true,
    redirect: '',
    meta: {
      title: '校园活动',
      fa_icon: 'fa-bullhorn'
    },
    children: [
      { path: '', component: () => import(/* webpackChunkName: "activity.list" */'@/pages/activity/list'), name: 'actitity_list', meta: { exact:true,title: '活动列表', }},
      { path: 'my', component: () => import(/* webpackChunkName: "activity.my" */'@/pages/activity/my'), name: 'actitity_my', name: 'actitity_my', meta: { title: '我的活动', }},
      //{ path: 'manage', component: () => import('@/pages/activity/manage'), name: 'actitity_mgr', meta: { title: '活动管理',, roles: ['manage_options', 'app_activity'] }},
      { path: ':id/edit', component: () => import(/* webpackChunkName: "activity.edit" */'@/pages/activity/edit'), name: 'actitity_mgr_single', meta: { notitle:true,title: '活动编辑', },hidden: true},
      { path: ':id/ticket', component: () => import(/* webpackChunkName: "activity.ticket" */'@/pages/activity/ticket'), name: 'actitity_ticket_single', meta: { notitle:true,title: '活动票务',  },hidden: true},
      { path: 'checkin', component: () => import(/* webpackChunkName: "activity.check" */'@/pages/activity/checkin'), name: 'actitity_checkin', meta: { title: '现场验票',roles: ['manage_options', 'app_activity','xbx_check_activity']}}
    ]
  },
  {
    path: '/vote',
    component: Layout,
    redirect: '',
    alwaysShow:true,
    meta: {
      title: '投票盒子',
      fa_icon: 'fa-line-chart'
    },
    children: [
      { path: '', component: () => import(/* webpackChunkName: "vote.index" */'@/pages/vote/list'), name: 'vote_list', meta: { exact:true,title: '参加投票'}},
      { path: ':id/edit', component: () => import(/* webpackChunkName: "vote.edit" */'@/pages/vote/edit'), name: 'vote_mgr_single', meta: { notitle:true,title: '投票编辑' },hidden: true},
      { path: ':id/ticket', component: () => import(/* webpackChunkName: "vote.count" */'@/pages/vote/ticket'), name: 'vote_cnt_single', meta: { notitle:true,title: '投票统计' },hidden: true},
      { path: 'rt', component: () => import(/* webpackChunkName: "vote.input" */'@/pages/vote/rt') , name: 'vote_checkin', meta: { title: '唱票工具',roles: ['manage_options', 'app_votebox','xbx_check_vote']}},
      { path: 'rt/scan', component: () => import(/* webpackChunkName: "vote.scan" */'@/pages/vote/rtscan') , name: 'vote_scan', hidden:true, meta: {title: '唱票扫描',roles: ['manage_options', 'app_votebox','xbx_check_vote']}}
    ]
  }, 
  /*{
    path: '/gateboy',
    component: Layout,
    redirect: '',
    meta: {
      title: '电子班牌',
      fa_icon: 'fa-desktop',
      roles: ['publish_posts', 'app_gateboy']
    },
    children: [
      { path: '', component: () => import('@/views/errorPage/404'), name: 'sc_class', meta: { title: '班级管理'}},
      { path: 'manage', component: () => import('@/views/errorPage/404'), name: 'sc_mgr', meta: { title: '设备管理'}},
      { path: 'publish', component: () => import('@/views/errorPage/404'), name: 'sc_publish', meta: { title: '内容发布'}}
    ]
  },*/
  {
    path: '/disk',
    component: Layout,
    alwaysShow:true,
    redirect: '',
    meta: {
      title: '学云共享',
      fa_icon: 'fa-hdd-o',
      roles: ['xbx_use_ac_disk','xbx_use_xysu_disk','xbx_use_xysuhc_disk',"manage_options"]
    },
    children: [
      { path: 'ac', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'ac_disk', meta: { 
        exact:true,title: 'AC网盘',showBack:false,
        iframe:env.XBX_MAIN_API+"/api/disk/ac",
        roles: ['xbx_use_ac_disk',"manage_options"]
      } },
      { path: 'xysu', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'xysu_disk', meta: { 
        exact:true,title: '学生会网盘',showBack:false,
        iframe:env.XBX_MAIN_API+"/api/disk/xysu",
        roles: ['xbx_use_xysu_disk',"manage_options"]
      } },
      { path: 'xysuhc', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'xysuhc_disk', meta: { 
        exact:true,title: '海沧学生会网盘',showBack:false,
        iframe:env.XBX_MAIN_API+"/api/disk/xysuhc",
        roles: ['xbx_use_xysuhc_disk',"manage_options"]
      } },
    ]
  },
  {
    path: '/wifi',
    component: Layout,
    alwaysShow:true,
    redirect: '',
    meta: {
      title: '无线网络',
      fa_icon: 'fa-wifi',
      roles: ['xbx_use_wifi','manage_options']
    },
    children: [
      // { path: 'devices', component: () => import('@/views/errorPage/404'), name: 'wifi_devices', meta: { exact:true,title: '我的设备'} },
      { path: 'passcode', component: () => import('@/pages/admin/passcode'/* webpackChunkName: "auth.passcode" */), name: 'wifi_passcode', meta: { exact:true,title: '授权管理',passcodeService:"wifi",passcodeTitle:"Wifi授权码"} },
    ]
  },
  {
    hidden:true,
    path: '/weixin',
    component:Layout,
    redirect: '',
    alwaysShow:true,
    meta: {
      title: '微信平台',
      icon: 'wechat',
      roles: ['view_weixin']
    },
    children: [
      { path: '', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'weixin', 
          meta: { exact:true,title: '微信统计',iframe:env.XBX_WEB_URL+"/wp-admin/admin.php?page=weixin-robot-stats"}},
      { path: 'view/user', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'weixins-usr', 
          meta: { title: '用户统计',iframe:env.XBX_WEB_URL+"/wp-admin/admin.php?page=weixin-robot-users-stats"}},
      { path: 'view/msg', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'weixins-msg', 
          meta: { title: '消息统计',iframe:env.XBX_WEB_URL+"/wp-admin/admin.php?page=weixin-robot-messages-stats"}},
      { path: 'view/menu', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'weixins-menu', 
          meta: { title: '菜单统计',iframe:env.XBX_WEB_URL+"/wp-admin/admin.php?page=weixin-robot-menu-stats"}},
      { path: 'menu', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'weixin-menu', 
          meta: { title: '自定义菜单',iframe:env.XBX_WEB_URL+"/wp-admin/admin.php?page=weixin-robot-menu"}},
      { path: 'reply', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'weixin-reply', 
          meta: { title: '自定义回复',iframe:env.XBX_WEB_URL+"/wp-admin/admin.php?page=weixin-robot-replies"}},
      { path: 'user', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'weixin-user', 
          meta: { title: '用户管理',iframe:env.XBX_WEB_URL+"/wp-admin/admin.php?page=weixin-robot-users"}},
      { path: 'msg', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'weixin-msg', 
          meta: { title: '消息管理',iframe:env.XBX_WEB_URL+"/wp-admin/admin.php?page=weixin-robot-messages"}},
    ]
  },
  {
    path: '/sms',
    component:Layout,
    alwaysShow:true,
    redirect: '',
    meta: {
      title: '短信平台',
      fa_icon: 'fa-commenting',
      roles: ['export', 'app_sms']
    },
    children: [
      { path: 'list', component: () => import(/* webpackChunkName: "sms.list" */'@/pages/sms/list'), name: 'sms', meta: {title: '短信记录',roles: ['export', 'app_sms']}},
      { path: 'template', component: () => import(/* webpackChunkName: "sms.template" */'@/pages/sms/template'), name: 'sms', meta: {title: '短信模板',roles: ['export', 'app_sms']}},
    ]
  },
{
    path: '/cms/post',
    component:Layout,
    redirect: '',
    meta: {
      title: '文章管理',
      fa_icon: 'fa-book',
      roles: ['publish_posts']
    },
    children: [
      { path: 'manage', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'posts', meta: { exact:true,title: '所有文章',iframe:env.XBX_MAIN_API+"/wp-admin/edit.php"}},
      { path: 'new', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'wpadmin_postnew', meta: { title:"写文章",iframe: env.XBX_MAIN_API+'/wp-admin/post-new.php'}},
      { path: 'cat', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'wpadmin_cat', meta: { title:"分类目录",iframe: env.XBX_MAIN_API+'/wp-admin/edit-tags.php?taxonomy=category'}},
      { path: 'comment', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'wpadmin_cmt', meta: { title:"评论管理",iframe: env.XBX_MAIN_API+'/wp-admin/edit-comments.php'}},
    ]
  },
  {
    hidden:true,
    path: '/pay',
    component: Layout,
    redirect: '',
    alwaysShow: true,
    meta: {
      title: '支付收款',
      roles: ['export',"xbx_pay"],
      fa_icon: 'fa-rmb',
    },
    children: [
      { path: '', component: () => import(/* webpackChunkName: "pay.pay" */'@/pages/payment/pay'), name: 'dopay', meta: { title: '手机支付'}},
    ]
  },
  {
    path: '/wp-admin',
    component:Layout,
    redirect: '',
    alwaysShow: true ,
    meta: {
      title: '鹳狸猿', 
      fa_icon: 'fa-wrench',
      roles: ['export',"list_users","xbx_customize"]
    },
    children: [
      { path: '', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'wpadmin', meta: { roles: ['export'],title: 'WP后台',iframe:env.XBX_MAIN_API+"/wp-admin/#non-fr",link:env.XBX_MAIN_API+"/wp-admin/",authredirect:true}},
      { path: 'users', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'wpadmin_user', meta: { title:"用户管理",roles: ['list_users'],iframe: env.XBX_MAIN_API+'/wp-admin/users.php'}},
      { path: 'media', component: () => import(/* webpackChunkName: "common.iframe" */'@/pages/iframe'), name: 'wpadmin_media', meta: { title:"媒体库",roles: ['publish_posts'],iframe: env.XBX_MAIN_API+'/wp-admin/media.php'}},
      { path: 'customize', component: () => import(/* webpackChunkName: "admin.customize" */'@/pages/admin/customize'), name: 'wpadmin_customize', meta: { title:"样式定制",roles: ['export','xbx_customize']}},
      { path: 'publicdomain', component: () => import(/* webpackChunkName: "admin.publicdomain" */'@/pages/admin/publicdomain'), name: 'wpadmin_publicdomain', meta: { title:"访问配置",roles: ['export','xbx_publicdomain']}},
      { path: 'maintenance', component: () => import(/* webpackChunkName: "admin.maintenance" */'@/pages/admin/maintenance'), name: 'wpadmin_maintenance', meta: { title:"维护模式",roles: ['export','manage_options']}},
    ]
  },
  {
    path: '/xuebuxi',
    component: Layout,
    redirect: '',
    meta: {
      title: '关于&反馈',
      fa_icon: 'fa-info',
    },
    children: [
      { path: '', component: () => import(/* webpackChunkName: "common.about" */'@/pages/user/about'), name: 'about', meta: { title: '关于&反馈', fa_icon: 'fa-info'}},
    ]
  },

  { path: '*', component: Layout,children:[{ path: '', component: () => import('@/views/errorPage/404'),meta: { title: '你似乎迷路啦'}}],hidden: true }
]
