<template>
  <el-scrollbar wrapClass="scrollbar-wrapper">
      <sidebar-item class="xmenu" :routes="routes"></sidebar-item>
  </el-scrollbar>
</template>
<script>
import SidebarItem from './newSidebarItem.vue'
import { mapGetters } from 'vuex'
export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters({
      routes:'permission_routers',
      sidebar:'sidebar'
    }),
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>