import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'


import env from './env' //APP版本
Vue.prototype.$xbxenv=env;

import 'normalize.css/normalize.css'// A modern alternative to CSS resets

import Element from '@/element-ui.js'
import VueViewload from 'vue-viewload'
//import '@/styles/element-theme/index.css'
import 'font-awesome/css/font-awesome.min.css'

import '@/styles/element-custom.scss' // element ui css
import '@/styles/index.scss' // global css

import App from './App'
import store from './store/index'
import router from './router'

import './icons/index' // icon
import './errorLog'// error log
import './permission' // permission control

import * as filters from './filters' // global filters

Vue.use(VueCompositionApi)

Vue.use(Element, {
    size: 'medium' // set element-ui default size
})
Vue.use(VueViewload,{
  threshold:-100,
  effectFadeIn:true
})

// register global utility filters.
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false
window.XBX=new Vue({
  el: '#tokiapp',
  router,
  store,
  render: h => h(App),
  data:{
    theme:""
  }
})
/* message bus for api iframe */
window.addEventListener('message',function(event) {
  if(env.XBX_MAIN_API.indexOf(event.origin)==-1&&event.origin!=this.location.origin)
    throw {name : "EventOriginInvalidError", message : "Eventbus got a message from an unknown origin",event:event};
    if(Array.isArray(event.data)&&event.data[0]=="_XBX"){
      console.log('[EventBus][ '+event.origin+' ]['+event.data[1]+']',event.data[2]);
      window.XBX.$emit("window."+event.data[1],event.data[2]);
    }
},false);
/*api iframe router push*/
window.XBX.$on("window.router.push",function(e){
  router.push(e);
})