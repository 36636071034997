import request from '@/utils/request'
import apis from '@/utils/fly'

export function loginByUsername(username, password, captchaRes=['','']) {
  const data = {
    username,
    password,
    token: captchaRes[0]||'',
    rand: captchaRes[1]||'',
  }
  return apis.v1.post('/user/login', data)
}

export function logout() {
  localStorage.xbToken="";
  return request({
    url: '/user/logout',
    method: 'post'
  })
}

export function getUserInfo() {
  return request({
    url: '/user/info',
    method: 'get'
  })
}

