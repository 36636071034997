import { loginByUsername, logout, getUserInfo } from '@/api/login'
import * as  Sentry from '@sentry/browser'
const user = {
  state: {
    isLogin:false,
    user: '',
    status: '',
    code: '',
    token: "",
    name: '',
    avatar: '',
    introduction: '',
    roles: [],
    setting: {
      articlePlatform: []
    },data:{}
  },

  mutations: {
    SET_CODE: (state, code) => {
      state.code = code
    },
    SET_LOGIN: (state, token) => {
      state.isLogin = token
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_INTRODUCTION: (state, introduction) => {
      state.introduction = introduction
    },
    SET_SETTING: (state, setting) => {
      state.setting = setting
    },
    SET_STATUS: (state, status) => {
      state.status = status
    },
    SET_FULL: (state, data) => {
      state.data = data
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    }
  },

  actions: {
    // 用户名登录
    LoginByUsername({ dispatch }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        loginByUsername(username, userInfo.password, userInfo.captchaRes||[]).then(response => {
          const data = response.data
          localStorage.xbToken = data.token||"";
          resolve()
        }).catch(error => {
          if(error.status===402&&userInfo.captcha){
              userInfo.captcha.verify().then(async (captchaRes)=>{
                resolve(await dispatch('LoginByUsername',{...userInfo,captchaRes}))
              }).catch(()=>{
                reject(error)
              })
            return
          }
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(response => {
          document.body.className=document.body.className.replace("appInit","");
          if (!response.data) { // 由于mockjs 不支持自定义状态码只能这样hack
            reject('error')
          }
          const data = response.data

          if (data.roles && data.roles.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_LOGIN', true)
            commit('SET_ROLES', data.roles)
          } else {
            reject('getInfo: roles must be a non-null array !')
          }

          commit('SET_NAME', data.display_name)
          commit('SET_AVATAR', data.avatar)
          commit('SET_FULL', data)
          commit('SET_INTRODUCTION', data.introduction)
          Sentry.setUser({
            id:data.user_login,
            display_name:data.display_name
          });
          resolve(response)
        }).catch(error => {
          document.body.className=document.body.className.replace("appInit","");
          reject(error)
        })
      })
    },

    // 第三方验证登录
    // LoginByThirdparty({ commit, state }, code) {
    //   return new Promise((resolve, reject) => {
    //     commit('SET_CODE', code)
    //     loginByThirdparty(state.status, state.email, state.code).then(response => {
    //       commit('SET_TOKEN', response.data.token)
    //       setToken(response.data.token)
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_ROLES', [])
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_ROLES', [])
        resolve()
      })
    }
  }
}

export default user
