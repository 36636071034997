import router from './router'
import store from './store/index'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import '@/styles/nprogress.css'// progress bar style
import env from '@/env' // getToken from cookie
import Vue from 'vue'

NProgress.configure({ showSpinner: false })// NProgress Configuration

// permission judge function
function hasPermission(roles, permissionRoles) {
  if (roles.indexOf('admin') >= 0) return true // admin permission passed directly
  if (!permissionRoles) return true
  return roles.some(role => permissionRoles.indexOf(role) >= 0)
}

const whiteList = ['/login', '/503']// no redirect whitelist
function finLogin(to,next){
      if(to.query.reauth){
        localStorage.xbToken="";
        return location.reload();
      }
      var rurl=to.query.redirect_to;
      if(to.query.withToken){
        let sep="?";
        if(rurl.includes("?"))sep="&";
        rurl+=sep;
        rurl+="xbToken="+localStorage.xbToken;
      }
      if(rurl)return location.replace(rurl);
      next('/');
}
router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  if (store.getters.login) { // determine if there has token
    if (to.path === '/login' || to.path === '/503') {
      finLogin(to,next);
      NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
    } else {
      if (store.getters.roles.length === 0) { // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetUserInfo').then(res => { // 拉取user_info
          const roles = res.data.roles // note: roles must be a array! such as: ['editor','develop']
          store.dispatch('GenerateRoutes', { roles }).then(() => { // 根据roles权限生成可访问的路由表
            router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
          })
        }).catch((err) => {
          store.dispatch('FedLogOut').then(() => {
            Message.error(err || 'Verification failed, please login again')
            next({ path: '/login' })
          })
        })
      } else {
        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
        if (hasPermission(store.getters.roles, to.meta.roles)) {
          next()//
        } else {
          next({ path: '/401', replace: true, query: { noGoBack: true }})
        }
        // 可删 ↑
      }
    }
  } else {
    /* has no token*/
      store.dispatch('GetUserInfo').then(res => { // 拉取user_info
          const roles = res.data.roles // note: roles must be a array! such as: ['editor','develop']
          store.dispatch('GenerateRoutes', { roles }).then(() => { // 根据roles权限生成可访问的路由表
            router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
             // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
            if (to.path === '/login') {
              finLogin(to,next)
            }else{
              next({ ...to, replace: true })
            }
            NProgress.done() // if current page is dashboard will not trigger	afterEach hook, so manually handle it
          })
        NProgress.done()
      }).catch((err) => {
        if( err.response && err.response.status==503 && to.path!=="/503" ){
          next({ path: '/503',query:{redirect_to:to.path.replace(new RegExp("//","g"),"/").replace(":/","://")} })
          return;
        }
        if (to.meta.noLogin==true||whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
          next()
        } else {
          next({ path: '/login',query:{redirect_to:to.path.replace(new RegExp("//","g"),"/").replace(":/","://")} })
        }
        NProgress.done()
      })
  }
})

router.afterEach((to,from) => {
  if(typeof(to.meta.notitle)=="undefined")document.title=(to.meta.title?to.meta.title+" | 学不习":"学不习")
  window.NProgress = NProgress;
  NProgress.done() // finish progress bar
  document.getElementById("vueload").classList.add("hide")
    if(env.XBX_APP_ENV=="test"){
        import('@/utils/watermark').then(function(e){
            var i=[0,300,500,700,900];
            for(var j of i)setTimeout(e.default,j);
            window._wtmr=e.default;
        })
    }
})
