<template>
    <div class="cms-wrap">
        <div class="cms-single">
            <router-view class="cms-article"></router-view>
        </div>
    </div>
</template>
<script>
export default {
    components:{
    },
    data(){
        return {
            
        }
    },
}
</script>
<style lang="scss" scoped>
.cms-wrap{
    background: #eee;
    padding:20px 0;
}
.cms-single {
    width: 1050px;
    max-width: 100%;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    *{
        box-sizing: border-box;
    }
    &::after {
        display: block;
        content: "";
        clear: both;
    }
    &::v-deep{
        .cms-sidebar{
            border-left: 1px solid #eee;
            padding-left: 10px;
            width: 210px;
            position: absolute;
            right: 0;
            bottom: 20px;
            top: 20px;
                .index{
                    h3 {
                        font-weight: normal;
                        margin-bottom: 2px;
                        color: #444;
                        border-left: 3px solid #ddd;
                        padding: 2px;
                        padding-left: 6px;
                        margin-top:0;
                    }
                    ul {
                        padding: 0;
                        margin: 0;
                        li {
                            font-size:0.9rem;
                            padding: 6px;
                            color: #777;
                            letter-spacing: .5px;
                            transition: ease-in-out .1s;
                            border-color: #e9e9e9;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                            &:hover{
                                background: #f7f8f9;
                                padding: 6px 6px 6px 10px;
                                border-left: 5px solid #f1f2f3;
                            }
                        }
                        .index-h2 {
                            padding-left: 10px;
                        }
                        .index-h3 {
                            padding-left: 20px;
                        }
                    }
                }
        }
    }
}
@media screen and (max-width: 1240px) {
    .mobile .cms-single,.openSidebar .cms-single{
        width:100%;
        &::v-deep{
            article,.cms-sidebar{
                float:none;
                position: static;
                border:0;
                width:100%;
            }
            article{
                padding-right: 0;
            }
            .cms-single-wrap article{
                padding-right: 20px;
            }
        }
    }
}
@media screen and (max-width: 1100px) {
    .cms-wrap{
        padding-top:0;
    }
    .mobile .cms-single,.hideSidebar .cms-single{
        width:100%;
        &::v-deep{
            article>h1{
                margin-top:0;
                padding-top:20px;
            }
            article,.cms-sidebar{
                float:none;
                position: static;
                border:0;
                width:100%;
            }
            article{
                padding-right: 0;
            }
            .cms-single-wrap article{
                padding-right: 20px;
            }
            .el-page-header__content{
                font-size: 1.5rem;
                line-height:1.2;
                width: calc( 100% - 40px );
            }
            .el-page-header__title{
                display:none;
            }
            .entry-meta{
                padding-left:50px;
            }
        }
    }
}
</style>
