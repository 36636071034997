import env from "@/env";
import Fly from "flyio/dist/npm/fly";
import { Notification } from "element-ui";
var apiurl = {
    wp: "/xbx-json/wp/v2",
    cms: "/xbx-json/xuebuxi-cms/v1",
    v1: "/api",
};
var apis = {};
for (var i in apiurl) {
    var fly = new Fly();
    fly.config.baseURL = env.XBX_MAIN_API + apiurl[i];
    //fly.config.withCredentials=true;
    fly.interceptors.request.use((request) => {
        request.headers.Authorization = "Bearer " + localStorage.xbToken;
        request.headers["X-Requested-With"] = "XMLHttpRequest";
        return request;
    });
    fly.interceptors.response.use(
        (response) => response,
        (error) => {
            console.error(error); // for debug
            if (
                ![402].includes(error.status) ||
                (error.status === 401 &&
                    (error.response.data.msg || error.response.data.error))
            ) {
                Notification({
                    title: "出错了！",
                    message:
                        (error.response
                            ? error.response.data.msg ||
                              error.response.data.error
                            : undefined) || error.message,
                    type: "error",
                    duration: 5 * 1000,
                });
            }
            return Promise.reject(error);
        }
    );
    apis[i] = fly;
}
export default apis;
